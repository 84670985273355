import React, { useEffect, KeyboardEvent, useRef } from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DispatchType } from '../../../reducers';
import {
  deleteUserAction,
  getUserAction, postUserAction, putUserAction,
} from '../../../actions/users';

import {
  InputEmail,
  ErrorField,
  InputText,
  StyledCreatable,
  StyledSelect,
} from '../../../lib/HooksFormFields';
import { IUser } from '../../../types/auth.types';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import styles from './User.module.scss';
import Loader from '../../../components/Loader';
import ModalStandalone, { IModalHandle } from '../../../lib/ModalStandalone';
import { getAllStructuresAction, postStructureAction } from '../../../actions/structures';
import { IOption } from '../../../lib/HooksFormFields/StyledSelect';
import { IStructure } from '../../../types/structure.types';
import CustomConfirm from '../../../lib/CustomConfirm/CustomConfirm';

export default function User({ role }: { role: string }) {
  const dispatch: DispatchType = useAppDispatch();
  const params = useParams();
  const modalRef = useRef<IModalHandle>(null);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IUser>({
    defaultValues: {
      role,
    },
  });
  const { authReducer } = useAppSelector((state: any) => state);
  const userAuth = authReducer?.user;
  const { user, error, isLoading } = useAppSelector((state: any) => state.userReducer);
  const { structuresList } = useAppSelector((state: any) => state.structureReducer);
  const { backUrl, lists } = useAppSelector((state: any) => state.orderReducer);
  const structuresOptions: IOption[] | null = structuresList?.map((s: IStructure) => ({ value: s._id, label: s.name }));
  const requiredRule = { required: 'Ce champs est requis' };

  function onSubmit(data: IUser) {
    if (user?._id) {
      putUserAction(dispatch, user?._id, {
        ...data,
        role: data?.role?.value || role,
        structures: data.structures.map((s: IOption) => s.value),
      });
    } else {
      const structures = data?.structures?.length > 0 ? data?.structures?.map((s: IOption) => s.value) : [];
      postUserAction(dispatch, {
        ...data,
        role: data?.role?.value || role,
        structures,
      });
    }
  }

  function deleteUser() {
    if (user?._id && modalRef?.current) {
      deleteUserAction(dispatch, user._id);
      modalRef.current.close();
      navigate(backUrl);
    }
  }

  function handlePressEnter(e: KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  useEffect(() => {
    getAllStructuresAction(dispatch);
  }, []);

  useEffect(() => {
    if (params?.id) {
      getUserAction(dispatch, params.id);
    }
  }, [params?.id]);

  useEffect(() => {
    if (lists?.roles) {
      if (user?._id) {
        const userForm = {
          profile: user.profile,
          email: user.email,
          tel: user.tel,
          role: lists.roles.find((r :IOption) => r.value === user.role),
          structures: user.structures.map((s: IStructure) => ({ value: s._id, label: s.name })),
        };
        reset(userForm);
      } else {
        const userForm = {
          role: lists.roles.find((r :IOption) => r.value === role),
        };
        reset(userForm);
      }
    }
  }, [user, lists]);

  return (
    <div className={styles.container}>
      <header>
        <Link to={backUrl} className={styles.back}>
          <HiOutlineArrowLeft size={20} />
          <p>Retour</p>
        </Link>
        <h2>
          {user?._id
            ? (
              <span>
                {user?.profile?.firstName}
                {' '}
                {user?.profile?.lastName}
              </span>
            )
            : (
              <span>
                {role === 'Member' ? 'Nouveau client' : 'Nouveau membre'}
              </span>
            )}
        </h2>
      </header>
      {isLoading
        ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        )
        : (
          <form autoComplete="off" className={styles.form}>
            <div onKeyDown={handlePressEnter}>
              {(userAuth?.role === 'Admin' && role !== 'Member')
              && (
              <div className={styles.row}>
                <div className={styles['container-field']}>
                  <StyledSelect
                    name="role"
                    control={control}
                    rules={requiredRule}
                    label="Rôle"
                    placeholder="Séléctionner..."
                    options={lists?.roles || []}
                  />
                  {errors.role && <ErrorField message={errors.role.message} />}
                </div>
              </div>
              )}
              <div className={styles.row}>
                <div className={styles['container-field']}>
                  <InputText
                    name="profile.lastName"
                    className="primary"
                    control={control}
                    rules={requiredRule}
                    label="Nom"
                    placeholder="Nom..."
                  />
                  {errors?.profile?.lastName && <ErrorField message={errors.profile?.lastName.message} />}
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="profile.firstName"
                    className="primary"
                    control={control}
                    rules={requiredRule}
                    label="Prénom"
                    placeholder="Prénom..."
                  />
                  {errors?.profile?.firstName && <ErrorField message={errors.profile?.firstName.message} />}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles['container-field']}>
                  <InputEmail
                    name="email"
                    className="primary"
                    control={control}
                    required
                    label="Mail"
                    placeholder="Adresse email..."
                  />
                  {errors.email && <ErrorField message={errors.email.message} />}
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="tel"
                    className="primary"
                    control={control}
                    rules={requiredRule}
                    label="Téléphone"
                    placeholder="Tel..."
                  />
                  {errors?.tel && <ErrorField message={errors.tel.message} />}
                </div>
              </div>
              {(role === 'Member' || watch('role')?.value === 'Moderator')
              && (
                <div className={styles.structure}>
                  <div className={styles['container-field']}>
                    <StyledCreatable
                      name="structures"
                      control={control}
                      onChange={(opt: IOption[]) => setValue('structures', opt)}
                      createOption={(opt: IOption) => postStructureAction(dispatch, { name: opt.value })}
                      rules={requiredRule}
                      isMulti
                      label="Établissements"
                      placeholder="Ajouter des établissements..."
                      options={structuresOptions || []}
                    />
                    {errors?.structures?.message && <ErrorField message={errors.structures.message} />}
                  </div>
                </div>
              )}
              {(!errors.email && !errors.password) && error
              && (
                <p className={styles['message-error']}>
                  {error}
                </p>
              )}
            </div>
            <div className={styles['btn-container']}>
              <button
                type="button"
                className={`${styles.btn} ${styles.primary}`}
                onClick={handleSubmit(onSubmit)}
              >
                Enregister
              </button>
              {user?._id !== userAuth?._id
                && (
                <button
                  type="button"
                  className={`${styles.btn} ${styles.danger}`}
                  onClick={() => modalRef?.current && modalRef.current.open()}
                >
                  Supprimer l&apos;utilisateur
                </button>
                )}
            </div>
          </form>
        )}
      <ModalStandalone ref={modalRef}>
        <CustomConfirm
          message="Veuillez confirmer la suppression de l'utilisateur."
          btnText="Confirmer la suppression"
          action={() => deleteUser()}
        />
      </ModalStandalone>
    </div>
  );
}
